<template>
    <div>
        <table-config-settings/>
    </div>
</template>

<script>
import TableConfigSettings from '@/components/config/settings/table.vue'
export default {
  components: { TableConfigSettings },
    data () {
        return {
           
        }
    }
    
}
</script>